


























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    SenderPrepare: () => import('./sender-prepare.vue'),
    SenderConfirm: () => import('./sender-confirm.vue'),
    SenderSending: () => import('./sender-sending.vue')
  }
})
export default class Stepper extends Vue {
  @Prop() currentStep!: number

  step1 = 1
  step2 = 2
  step3 = 3
}
